import { BrowserRouter, useLocation } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { useEffect } from "react";
import "antd/dist/antd.min.css";
import ReactGA from 'react-ga';

import Router from "./router";
import i18n from "./translation";

const TRACKING_ID = "G-N786XPKD7T";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  );
};

const Main = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<Main />);
} else {
  console.error("Root element not found");
}
